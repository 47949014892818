<template>
  <CardToolbar
    description="The size of Artist's fanbase and distribution across social media and
            music channels"
    :show-toolbar-buttons="false"
    title="Total Fanbase Distribution"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" animated />
      <el-row v-else>
        <el-col :xs="24" :md="12" :lg="12">
          <DonutChart :series="series" :labels="labels" />
        </el-col>
        <el-col :xs="24" :md="12" :lg="12">
          <el-row :gutter="10" class="platform-columns">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              v-for="data in platformFollowers"
              :key="data.platform"
              class="mb-3"
            >
              <FanbasePlatform
                :followers="
                  data.dataFound
                    ? formatNumber(data.followers).toString()
                    : 'N/A'
                "
                :platform="data.platform"
                :has-data="data.dataFound"
                :indicator="platforms[data.platform.toLowerCase()].indicator"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { getAllPlatformFanDistribution } from "@/api/artists.api";
import { useNumberFormatter } from "@/common/composables/useNumberFormatter";
import { platforms } from "../../assets/static/platforms";

import DonutChart from "./DonutChart";
import FanbasePlatform from "./FanbasePlatform";
import CardToolbar from "../../common/components/CardToolbar";

export default {
  components: { CardToolbar, FanbasePlatform, DonutChart },
  setup() {
    const loading = ref(true);
    const series = ref([]);
    const labels = ref([]);
    const platformFollowers = ref([]);
    const route = useRoute();
    const { formatNumber } = useNumberFormatter();

    onMounted(async () => {
      try {
        await fetchFanbaseDistribution(route.params.uuid);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    async function fetchFanbaseDistribution(uuid) {
      const response = await getAllPlatformFanDistribution(uuid);
      platformFollowers.value = response.data.platformFollowersDTOS;
      series.value = response.data.apexChartDTO.yAxis.filter(
        (value) => value !== null
      );
      labels.value = response.data.apexChartDTO.xAxis.filter(
        (value) => value !== null
      );
    }
    return {
      loading,
      series,
      labels,
      platformFollowers,
      formatNumber,
      platforms,
    };
  },
};
</script>

<style lang="scss" scoped></style>
